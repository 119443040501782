
/* ------------  General Site Responsive ----------- */
@media all and (min-width: 1271px)  {
	#MobileNavigation{
		display: none !important;
	}
	.activeMobileMenu{
		overflow-y: auto !important;
	}
	#MenuIcon{
		display: none !important;
	}
}


@media all and (max-width: 880px)  {
	.pageList{
		a{
			margin-left: 0px !important;
			margin-right: 0px !important;
			width: 100% !important;
		}
	}
}
@media all and (max-width: 1385px)  {
	.homebtn img {
	    height: 60px;
	}
}
@media all and (max-width: 1270px)  {
	#MenuIcon{
		display: block !important;
	}
	.translate {
    	top: 21px;
    	right: 116px;
    }
	#Menu{
		display: none !important;
	}

	.homebtn img {
	    height: 37px;
	}
	#header{
		height: 70px;
	}
	.PageContainer{
		margin-top: 70px;
	}
	.topLinks {
	    top: 20px;
	    right: 81px;
	    font-size: 0.9em;
	}
	.SearchContainer {
	    top: 27px;
	}
}


@media all and (max-width: 1050px)  {
	.InnerPage .pageTitle {
	    font-size: 2em !important;
	}
}
@media all and (max-width: 1200px)  {

	#sidebar .sidebar-heading a {
	    font-size: 1.2em !important;
	}
}
@media all and (max-width: 786px)  {
	.tacMid{
		text-align: center !important;
	}
	.clearMid{
		width: 100%;
		padding: 0px !important;
		clear: both;
	}
	* .pageTitle {
	    font-size: 3em !important;
	}

	
}
/*** Newsletters Page Responsive ***/
@media all and (max-width: 726px)  {
	.NewsletterItem{
		width: 50% !important;
	}
	.sideLinks a {
	    padding: 9px;
	    font-size: 1em;
	    margin: 2px 0px;
	    &:hover{
			padding: 9px 40px 9px 9px;
		}
	}

}
@media all and (max-width: 500px)  {
    .NewsletterItem{
		width: 100% !important;
	}
}
/*** Newsletters Page Responsive ***/

/*** Staff Responsive ***/
@media all and (max-width: 520px)  {
	.StaffPage .staffProfile .profilePic {
	    margin: 0 0px 30px 0 !important;
	    float: left;
	    width: 100%;
	    clear: both;
	    display: block;
	}
}
/*** Staff Responsive ***/
/*** Gallery Responsive ***/

@media all and (max-width: 800px)  {
    .galleryImage{
        width: 50% !important;
        padding-bottom: 50% !important;
    }
}
@media all and (max-width: 768px)  {
	.grid-item, .grid-sizer { 
		width: 50% !important; 
	}
}
@media all and (max-width: 500px)  {
    .galleryImage{
        width: 100% !important;
        padding-bottom: 100% !important;
    }
}

@media all and (max-width: 450px)  {
	.grid-item, .grid-sizer { 
		width: 100% !important; 
	}
}
/*** Gallery Responsive ***/

/* ------------  General Site Responsive ----------- */



@media all and (max-width: 1100px)  {
	.overlay4{
		bottom: 30px;
	}
}

@media all and (max-width: 1030px)  {
	.banner .bg{
		margin-top: 0px !important;
	}
	.flex{
		display: block;
	}
}
@media all and (max-width: 977px)  {
	#Home5 span {
	    padding: 0px 10px;
	    font-size: 2.5em;
	}
}
@media all and (max-width: 844px)  {
	#home2{
		.col-8,
		.col-4{
			width: 100%;
			clear: both;
			text-align: center;
		}
		.contentLeft{
		    width: 300px;
		    float: none;
		    margin: 0 auto;
		}
	}
}

@media all and (max-width: 763px)  {
	#Home4,
	#Home3{
		.col-4{
			clear: both;
			width: 100%;
		}
		.imageCircle{
			width: 200px;
			height: 200px;
			padding-bottom: 0px;
		}
	}

	#Home5 span {
	    padding: 0px 10px;
	    font-size: 1.5em;
	}
	footer{
		.col-2,
		.col-8{
			display: block;
			clear: both;
			text-align: center !important;
			width: 100% !important;
		}
		.ofstedLogo{
			width: 90%;
			max-width: 200px;
			margin: 10px 0px;
		}

		.fse{
		    display: block;
		    position: relative;
		    float: left;
		    clear: both;
		    width: 100%;
		    text-align: center;
		    font-size: 0.8em;
		}
	}
}

@media all and (max-width: 670px)  { 

.Hedgehog1,
.otter1,
.bee1,
.butterfly1,
.ladybird1,
	.overlay2,
	.overlay3{
		display: none !important;
	}

}
@media all and (max-width: 550px)  { 
	#Home5 span {
	    padding: 0px 10px;
	    font-size: 1em;
	}
}

@media all and (max-width: 491px)  { 
	.governorsLogin{
		display: none;
	}
}



@media all and (max-width: 1830px)  {
	.TopImage {
	    width: 250px;
	    height: 250px;
    }
}

@media all and (max-width: 1125px)  {
	.TopImage {
	    margin: 50px 10px;
	    width: 200px;
	    height: 200px;
	}
}
@media all and (max-width: 1572px)  {
	.topImage5{
		display: none;
	}
}
@media all and (max-width: 1264px)  {
	.topImage4,
	.topImage5{
		display: none;
	}
}

@media all and (max-width: 728px)  {
	.topImage3,
	.topImage4,
	.topImage5{
		display: none;
	}
}

@media all and (max-width: 520px)  {
	.topImage2,
	.topImage3,
	.topImage4,
	.topImage5{
		display: none;
	}
}


@media all and (max-width: 1000px)  {
	.quoteBox,
	.bannerText{
		text-align: center;
		font-size: 5em;
	}
}
@media all and (max-width: 877px)  {
	.otter1,
	.Hedgehog1{
		display: none !important;
	}
}
@media all and (max-width: 620px)  {
	.quoteBox,
	.bannerText{
		display: none !important;

	}
}
@media all and (max-width: 1039px)  {
	.animalSceneTwo{
		display: none !important;
	}
}
@media all and (max-width: 1100px)  {
	.animalSceneOne{
		display: none !important;
	}
}

@media all and (max-width: 1570px)  {
	.sideLinks a{
		padding: 10px;
	    font-size: 1em;
	}
}


